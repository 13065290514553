import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTab, MatTabChangeEvent } from '@angular/material/tabs';
import * as FileSaver from 'file-saver';
import { forkJoin } from 'rxjs';
import { CdaAsset } from 'src/app/models/cda-asset';
import { FaqPageResponse } from 'src/app/models/faq-page-response';
import { FormsAndDocumentsPageResponse } from 'src/app/models/forms-and-documents-page-response';
import { GlobalResponse } from 'src/app/models/global-response';
import { CmsService } from 'src/app/services/cms/cms.service';
import { EnvService } from 'src/app/services/env/env.service';
import { FileService } from 'src/app/services/file/file.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqsComponent implements OnInit {

  readonly FAQ_EMPLOYEE = "/assets/images/Hero-FAQ Employee.png";
  readonly FAQ_PROVIDER = "/assets/images/Hero-FAQ Provider.png";


  image: string | undefined;

  globalData: GlobalResponse | undefined;
  faqPageData: FaqPageResponse | undefined;
  formsPageData: FormsAndDocumentsPageResponse | undefined;

  //Global
  clientLongName: string | undefined;
  clientShortName: string | undefined;
  ffaLongName: string | undefined;
  ffaShortName: string | undefined;
  contactEmail: string | undefined;
  documentsEmail: string | undefined;
  newAppEmail: string | undefined;
  
  //FAQ Page
  tfi: string | undefined;
  employeeHeroImgUrl: string | undefined;
  providerHeroImgUrl: string | undefined;
  answer1Paragraph1: string | undefined;
  answer1Paragraph2: string | undefined;
  answer1Paragraph3: string | undefined;
  answer2NewEmployeeEligibility: string | undefined;
  answer3EmployeeEligibility: string | undefined;
  answer3Item1EmployeeRequirement: string | undefined;
  answer4AwardAmount: string | undefined;
  answer4Example1: string | undefined;
  answer4Example2: string | undefined;
  answer4Example3: string | undefined;
  answer6ApplicationDenial: string | undefined;
  answer17AdditionalQuestions : string | undefined;

  //Forms and Documents Page
  informationBrochure: CdaAsset | undefined;
  applicationChecklist: CdaAsset | undefined;
  opmForm1643: CdaAsset | undefined;
  opmForm1644: CdaAsset | undefined;
  irsFormW9: CdaAsset | undefined;
  monthlyInvoice: CdaAsset | undefined;
  childCareProviderACHAuthorization: CdaAsset | undefined;
  recertificationPackageChecklist: CdaAsset | undefined;

  constructor(private cms: CmsService, private env: EnvService, private fileService: FileService) { }

  ngOnInit(): void {


    forkJoin([this.cms.getGlobal(this.env.client), this.cms.getFaqPage(this.env.client), this.cms.getFormsAndDocumentsPageEntry(this.env.client)]).subscribe(([global, faqPage, formsPage]: [any, any, any]) => {
      console.log("Global Data");
      console.log(global);
      console.log("FAQ Page Data");
      console.log(faqPage);
      console.log("Forms and Documents Page Data");
      console.log(formsPage);
      this.globalData = global as GlobalResponse;
      this.faqPageData = faqPage as FaqPageResponse;
      this.formsPageData = formsPage as FormsAndDocumentsPageResponse;

      this.employeeHeroImgUrl = this.faqPageData.entry.employeeImage ? this.faqPageData.entry.employeeImage.url : '';
      this.providerHeroImgUrl = this.faqPageData.entry.providerImage ? this.faqPageData.entry.providerImage.url : '';
      this.answer1Paragraph1 = this.faqPageData.entry.answer1Paragraph1;
      this.answer1Paragraph2 = this.faqPageData.entry.answer1Paragraph2;
      this.answer1Paragraph3 = this.faqPageData.entry.answer1Paragraph3;
      this.answer2NewEmployeeEligibility = this.faqPageData.entry.answer2NewEmployeeEligibility;
      this.answer3EmployeeEligibility = this.faqPageData.entry.answer3EmployeeEligibility;
      this.answer3Item1EmployeeRequirement = this.faqPageData.entry.answer3Item1EmployeeRequirement;
      this.answer4AwardAmount = this.faqPageData.entry.answer4AwardAmount;

      this.answer4Example1 = this.faqPageData.entry.answer4Example1;
      this.answer4Example2 = this.faqPageData.entry.answer4Example2;
      this.answer4Example3 = this.faqPageData.entry.answer4Example3;
      this.answer6ApplicationDenial = this.faqPageData.entry.answer6ApplicationDenial;
      this.answer17AdditionalQuestions = this.faqPageData.entry.answer17AdditionalQuestions;

      this.informationBrochure = this.formsPageData.entry.informationalBrochure;
      this.applicationChecklist = this.formsPageData.entry.applicationChecklist;
      this.opmForm1643 = this.formsPageData.entry.opmForm1643;
      this.opmForm1644 = this.formsPageData.entry.opmForm1644;
      this.irsFormW9 = this.formsPageData.entry.irsFormW9;
      this.monthlyInvoice = this.formsPageData.entry.monthlyInvoiceForm;
      this.childCareProviderACHAuthorization = this.formsPageData.entry.childcareProviderAchAuthorizationForm;
      this.recertificationPackageChecklist = this.formsPageData.entry.recertificationPackageChecklistForm;

      //Initialize Image
      this.image = this.employeeHeroImgUrl
    })

  }

  onTabChanged($event : MatTabChangeEvent){
    // console.log($event);
    let tab : MatTab = $event.tab;
    if(tab.textLabel == "employees"){
      this.image = this.employeeHeroImgUrl;
    }
    else if(tab.textLabel == "providers"){
      this.image = this.providerHeroImgUrl;
    }
    else{
      console.error("Invalid tab selected");
    }
  }

  onFormClicked(form?: CdaAsset){
    console.log("Form selected: "+form?.title);
    if(form){
        this.fileService.downloadFile(form.url).subscribe((response: any) => { //when you use stricter type checking
          let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
          const url = window.URL.createObjectURL(blob);
          FileSaver.saveAs(blob, form.title+".pdf");
        }), 
        (error: any) => console.error('Error downloading the file'), //when you use stricter type checking
        () => console.info('File downloaded successfully');
    }
    else{
      console.error('File not provided')
    }
		
  }

}

