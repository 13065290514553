<div class="container">
    <div class="hero-container">
        <img class="hero-img" src="/assets/images/Hero-SecurityCompliance.png">
    </div>

    <div class="content">
        <div class="content-item">
            <span class="content-subheader">Protecting Personally Identifiable Information (PII)</span>
                <p>
                    The Federal Aviation Administration (FAA) Child Care Subsidy Program (CCSP) website was designed and developed by First Financial Associates 
                    (First Financial), and it is managed and operated by First Financial. The FAA contracted with First Financial in 2019 to provide management 
                    and administrative support services in connection with the FAA CCSP. As part of our services, we are required to collect and protect 
                    Personally Identifiable Information (PII) obtained from FAA employees and their childcare providers. 
                </p>
                <p>
                    PII is defined as information that can be used to identify you as an individual. PII includes your name, address, telephone number and any other 
                    information that is connected with you personally. As a general rule, First Financial does not collect PII about you when you visit our website, 
                    unless you choose to provide such information to us. Submitting PII through our website is voluntary. By doing so, you are giving First Financial 
                    your permission to use the information for the stated purpose. However, not providing certain information may result in our inability to provide 
                    you with the services you desire.
                </p>
                <p>
                    First Financial takes the security of all PII very seriously. We take precautions to maintain the security, confidentiality, and integrity of the 
                    information we collect at this site. Such measures include access controls designed to limit access to the information to the extent necessary to 
                    accomplish our required tasks and responsibilities. We also employ various security technologies to protect the information stored on our systems. 
                    We routinely test our security measures to ensure that they remain operational and effective.
                </p> 
                <p>
                    We take the following steps to secure the information we collect:
                </p> 
                <p>
                    • Employ internal access controls to ensure that only personnel who have access to your information are those with a need to do so to perform their official duties. 
                    <br>
                    • Train appropriate personnel on our privacy and security policies and compliance requirements.
                    <br> 
                    • Secure the areas where we retain paper copies of the information we collect online. 
                    <br>
                    • Perform regular backups of the information we collect online to ensure against loss. 
                    <br>
                    • Use technical controls to secure the information we collect online including, but not limited to: 
                    <br><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o Secure Socket Layer (SSL)
                    <br> 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o Encryption 
                    <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o Firewalls 
                    <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o Password protections 
                    <br><br>
                    • Periodically test our security procedures to ensure personnel and technical compliance. 
                    <br>
                    • Employ external access safeguards to identify and prevent unauthorized access by outsiders that attempt to “hack” into, or cause harm to, the information contained in our systems. 
                </p>      
        </div>
        <div class="content-item">
            <span class="content-subheader">Federal Compliance Standards</span>
            <p>
                The FAA CCSP online web application and database will be hosted and running on the Amazon Web Services (AWS) GovCloud. AWS GovCloud (US) is an isolated AWS 
                region designed to host sensitive data and regulated workloads in the cloud, helping customers support their U.S. government compliance requirements, 
                including the International Traffic in Arms Regulations (ITAR) and Federal Risk and Authorization Management Program (FedRAMP) High baseline requirements. 
                AWS GovCloud is a special, highly secured Amazon region built for United States government agencies and companies that work with and support them. 
                It operates alongside the even more secure Top Secret and Secret Regions that support the intelligence community and other agencies who store classified 
                information. 
            </p>
            <p>
                AWS GovCloud (U.S.) and all U.S. AWS Regions have received an Agency Authority to Operate (ATO) from the U.S. Department of Health and Human 
                Services (HHS) under the Federal Risk and Authorization Management Program (FedRAMP) requirements at the Moderate impact level. FedRAMP is a 
                U.S. government-wide program that provides a standardized approach to security assessment, authorization, and continuous monitoring for cloud 
                products and services. 
            </p>
             <p>
                The AWS GovCloud also helps protect sensitive unclassified data files with server-side encryption in Amazon S3. AWS GovCloud (US) gives government 
                agencies, their partners and contractors, the flexibility to architect secure cloud solutions that comply with the FedRAMP High baseline; 
                the DOJ’s Criminal Justice Information Systems (CJIS) Security Policy; U.S. International Traffic in Arms Regulations (ITAR); Export Administration 
                Regulations (EAR); Department of Defense (DoD) Cloud Computing Security Requirements Guide (SRG) for Impact Levels 2, 4 and 5; FIPS 140-2; IRS-1075; 
                and other compliance regimes. AWS GovCloud (US) uses FIPS 140-2 approved cryptographic modules for all AWS service API endpoints, unless otherwise 
                indicated. 
             </p>
             <p>
                AWS Cloud infrastructure and services have been validated by third-party testing performed against the NIST 800-53 Revision 4 controls, as well 
                as additional FedRAMP requirements. AWS has received FedRAMP Authorizations to Operate (ATO) from multiple authorizing agencies for both 
                AWS GovCloud (US) and the AWS US East/West Region.
             </p>   
        </div>
    </div>
</div>
