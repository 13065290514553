import { Component, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { forkJoin } from 'rxjs';
import { CdaAsset } from 'src/app/models/cda-asset';
import { FormsAndDocumentsPageResponse } from 'src/app/models/forms-and-documents-page-response';
import { GlobalResponse } from 'src/app/models/global-response';
import { CmsService } from 'src/app/services/cms/cms.service';
import { EnvService } from 'src/app/services/env/env.service';
import { FileService } from 'src/app/services/file/file.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {

  globalData: GlobalResponse | undefined;
  formsPageData: FormsAndDocumentsPageResponse | undefined;

  //Global
  clientLongName: string | undefined;
  clientShortName: string | undefined;
  ffaLongName: string | undefined;
  ffaShortName: string | undefined;
  
  //Forms and Documents Page
  heroImgUrl: string | undefined;
  informationBrochure: CdaAsset | undefined;
  applicationChecklist: CdaAsset | undefined;
  opmForm1643: CdaAsset | undefined;
  opmForm1644: CdaAsset | undefined;
  irsFormW9: CdaAsset | undefined;
  monthlyInvoice: CdaAsset | undefined;
  childCareProviderACHAuthorization: CdaAsset | undefined;
  recertificationPackageChecklist: CdaAsset | undefined;



  constructor(private cms: CmsService, private env: EnvService, private fileService: FileService) { }

  ngOnInit(): void {

    forkJoin([this.cms.getGlobal(this.env.client), this.cms.getFormsAndDocumentsPageEntry(this.env.client)]).subscribe(([global, formsPage]: [any, any]) => {
      console.log("Global Data");
      console.log(global);
      console.log("Forms and Documents Page Data");
      console.log(formsPage);
      this.globalData = global as GlobalResponse;
      this.formsPageData = formsPage as FormsAndDocumentsPageResponse;

      this.heroImgUrl = this.formsPageData.entry.image ? this.formsPageData.entry.image.url : '';
      this.informationBrochure = this.formsPageData.entry.informationalBrochure;
      this.applicationChecklist = this.formsPageData.entry.applicationChecklist;
      this.opmForm1643 = this.formsPageData.entry.opmForm1643;
      this.opmForm1644 = this.formsPageData.entry.opmForm1644;
      this.irsFormW9 = this.formsPageData.entry.irsFormW9;
      this.monthlyInvoice = this.formsPageData.entry.monthlyInvoiceForm;
      this.childCareProviderACHAuthorization = this.formsPageData.entry.childcareProviderAchAuthorizationForm;
      this.recertificationPackageChecklist = this.formsPageData.entry.recertificationPackageChecklistForm;
      
    })

  }


  onFormClicked(form?: CdaAsset){
    console.log("Form selected: "+form?.title);
    if(form){
        this.fileService.downloadFile(form.url).subscribe((response: any) => { //when you use stricter type checking
          let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
          const url = window.URL.createObjectURL(blob);
          FileSaver.saveAs(blob, form.title+".pdf");
        }), 
        (error: any) => console.error('Error downloading the file'), //when you use stricter type checking
        () => console.info('File downloaded successfully');
    }
    else{
      console.error('File not provided')
    }
		
  }

}
