<div class="container">
    <div class="hero-container">
        <img class="hero-img" [src]="aboutPage.aboutUsImage.url">
        <div class="hero-title">About</div>
    </div>

    <div class="about-us-container">
        <div class="about-us-items">
            <div class="about-us-headline">
                {{aboutPage.aboutUsHeadline}}
            </div>
            <div class="about-us-text">
                <!-- {{aboutPage.aboutUsText}} -->
                <markdown [data]="aboutPage.aboutUsText"></markdown>
            </div>
        </div>
        
    </div>

    <div class="about-us-client-container">
        <div class="about-us-client-items">
            <div class="about-us-client-headline">
                {{aboutPage.aboutUsClientListHeadline}}
            </div>
            <div class="about-us-client-list">
                <div *ngFor="let client of clients" class="about-us-client-logo">
                    <div class="client-pages-image">
                        <img class="client-logo-img" [src]="client.logo.url"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-us-client-text">
            {{aboutPage.aboutUsClientListText}}
        </div>
    </div>

    
</div>
