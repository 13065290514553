import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {createClient, Entry, ContentfulClientApi} from 'contentful';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  headers = new HttpHeaders({'Content-Type': 'application/json'})
  httpOptions: any;

  CCSP_CMS_ROOT = '/ccsp/contentful'

  // private client: ContentfulClientApi; 

  constructor(private env: EnvService, private http: HttpClient) { 
    this.httpOptions = {
      headers: this.headers,
      params: null
    }
  }

  getClientList(client:string){
    const params = new HttpParams()
      .set('client', client);

    this.httpOptions.params = params;
    this.httpOptions.observe = null;  

    return this.http.get(this.env.apiUrl + this.CCSP_CMS_ROOT + '/getClientList',this.httpOptions)
  }

  getGlobal(client: string){

    const params = new HttpParams()
      .set('client', client);

    this.httpOptions.params = params;
    this.httpOptions.observe = null;  

    return this.http.get(this.env.apiUrl + this.CCSP_CMS_ROOT + '/getFirstFinancialInfo',this.httpOptions)
  }

  getHomePage(client: string){
    const params = new HttpParams()
      .set('client', client);

    this.httpOptions.params = params;
    this.httpOptions.observe = null;  

    return this.http.get(this.env.apiUrl + this.CCSP_CMS_ROOT + '/getHomePageEntry',this.httpOptions)
  }

  getAboutPage(client: string){
    const params = new HttpParams()
      .set('client', client);

    this.httpOptions.params = params;
    this.httpOptions.observe = null;  

    return this.http.get(this.env.apiUrl + this.CCSP_CMS_ROOT + '/getAboutPageEntry',this.httpOptions)
  }

  getServicesPage(client: string){
    const params = new HttpParams()
      .set('client', client);

    this.httpOptions.params = params;
    this.httpOptions.observe = null;  

    return this.http.get(this.env.apiUrl + this.CCSP_CMS_ROOT + '/getServicesPageEntry',this.httpOptions)
  }

  getFaqPage(client: string){
    const params = new HttpParams()
      .set('client', client);

    this.httpOptions.params = params;
    this.httpOptions.observe = null;  

    return this.http.get(this.env.apiUrl + this.CCSP_CMS_ROOT + '/getFaqPageEntry',this.httpOptions)
  }

  getFormsAndDocumentsPageEntry(client: string){
    const params = new HttpParams()
      .set('client', client);

    this.httpOptions.params = params;
    this.httpOptions.observe = null;  

    return this.http.get(this.env.apiUrl + this.CCSP_CMS_ROOT + '/getFormsAndDocumentsPageEntry',this.httpOptions)
  }
}