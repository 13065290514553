<div class="container">
    <div class="hero-container">
        <hr class="hero-hr">
        <span class="hero-title">First Financial Associates</span>
        <hr class="hero-hr">
    </div>

    <div class="content">
        <div class="content-item">
            <span class="content-header">Privacy Policy</span>
            <p class="update-date">Updated: April 27, 2021</p>
            <p>
                First Financial Associates, Inc. (“First Financial” or “we”) takes your right to privacy seriously and wants you to feel comfortable using this website. 
                This privacy policy outlines what personally identifiable information (referred to as “data” below) about you may be collected by this site, how this data 
                is collected and how this data may be used by First Financial or disclosed to third parties. This policy does not apply to other entities that are not owned 
                or controlled by First Financial, nor does it apply to information collected by any third party through links available on our website. For information 
                regarding how these third parties use your data, we encourage you to review their respective privacy policies. 
            </p>
        </div>

        <div class="content-item">
            <span class="content-subheader">CALIFORNIA ONLINE PRIVACY PROTECTION ACT COMPLIANCE</span>
            <p>
                Because we value your privacy, we have taken the necessary precautions to be compliant with the California Online Privacy Protection Act. 
                We therefore will not distribute your personal information to outside parties without your consent.
            </p>
        </div>
         
        <div class="content-item">
            <span class="content-subheader">CHILDREN’S ONLINE PRIVACY PROTECTION ACT COMPLIANCE</span>
            <p>
                We are in compliance with the requirements of COPPA (Children’s Online Privacy Protection Act), we do not collect any information from anyone 
                under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or older. 
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">COLLECTION OF DATA</span>
            <p>
                First Financial respects the privacy of the people who visit our website. The data gathered by First Financial from this site falls into two 
                categories: (1) information voluntarily supplied by you and (2) tracking information gathered by cookies and web beacons as you navigate through 
                our site. 
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">Information You Provide to Us</span>
            <p>
                The information we collect may include information that you voluntarily provide to us by submitting questions on our website or requesting to be 
                included on our mailing list. This information may include your name, email address, home address, telephone number(s), and the name of your company 
                (if applicable).
                <br><br>
                If you submit a question online, we will also collect any other information which you voluntarily supply. If you utilize the search function of our 
                website, we will collect this information, but it is not stored with your IP address or any other information that would identify the user. We do not 
                share this data with anyone outside of First Financial.
            </p>
        </div>   
        <div class="content-item">
            <span class="content-subheader">Information We Collect Through Automatic Data Collection Technologies</span>
            <p>
                As you navigate through and interact with our website, we may use automatic data collection technologies to collect the following information about 
                your computer equipment and browsing activity:
                <br><br>
                • Details of your visits to our website, including: website traffic data (number of visits to our webpage, the time spent on each page, the particular 
                pages of the website that were viewed); server log files; location data (i.e., zip code location of device used to access website); and 
                <br>
                • Information about your computer and internet connection, including your IP address, operating system, and browser type. 
                <br>
                • Documents and forms from you to collect personally identifiable information (PII), including: income tax returns, paycheck stubs, employment 
                verification documents, childcare subsidy program application form, childcare provider information (rates, name, address, telephone, and email address). 
                <br><br>
                The information we collect through these technologies does not include information that identifies you, but we may maintain it or associate it with 
                such personally identifiable information we collect in other ways.
                <br><br>
                We primarily collect this information through the use of “cookies,” but we may also use web beacons. The first time you visit our website from any 
                computer, tablet, smartphone, or other internet-enabled device, you will be asked to accept “cookies.” “Cookies” are small data files created by a 
                website that are stored in the user’s computer. We do not use cookies to obtain any information that identifies you, but, when cookies are allowed 
                by you, we use cookies to obtain the information listed above and when you navigate to our webpage through a marketing email sent by us, we may be 
                able to connect your browser activity to your email address. Further, these cookies establish a user session and allow our server to efficiently 
                exchange data with you.
            </p>
        </div>   
        <div class="content-item">
            <span class="content-subheader">USE OF THE DATA WE COLLECT</span>
            <p>
                Data may be used to customize and improve your user experience on this site. We also use data collected from you to help us perform and provide the 
                administrative and management support services duties and responsibilities, that we are contracted to provide on behalf of your employer.
            </p>
            <p>
                Your data will not be disclosed to any third parties unless (i) provided for otherwise in this Privacy Policy; (ii) your consent is obtained, such as 
                when you choose to opt-in to or opt-out from the sharing of data; (iii) a service provided on our site requires interaction with a third party, or is 
                provided by a third party, such as an application service provider; (iv) pursuant to legal action or a request from law enforcement; (v) it is found 
                that your use of this site may violate the law or First Financial’s privacy policy, terms of service or other usage guidelines, or if it is deemed 
                reasonably necessary by us to protect First Financial’s legal rights and/or property; or (vi) this site is purchased by a third party, in which case 
                that third party will be able to use the data in the same manner as set forth in this policy.
            </p>
            <p>
                In the event you choose to use links displayed on this website to visit other websites, you are advised to read the privacy policies published on those 
                sites. We cannot be responsible for the privacy or security practices or the content of such non-affiliated, linked websites. 
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">SECURITY AND PROTECTION OF DATA</span>
            <p>
                We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and 
                disclosure. All information you provide to us is stored in a secured manner. However, the transmission of information via the internet is not 
                completely secure. While we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted 
                to our Website. Any transmission of personal information is at your own risk.
            </p>
        </div>    
          
        <div class="content-item">
            <span class="content-subheader">YOUR CONSENT</span>
            <p>
                By using our site, you consent to our privacy policy.
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">CHANGES TO THIS PRIVACY POLICY</span>
            <p>
                Changes may be made to this policy from time to time. If we revise our Privacy policy, we will update our Privacy Policy page on this site. 
                The date that our privacy policy was last revised is identified at the top of this page.
            </p>
        </div> 
        <div class="content-item">
            <span class="content-subheader">CONTACT INFORMATION</span>
            <p>
                If you have any questions about this policy or about this website, please feel free to contact the site administrator at 
                <span class="privacy-link">privacy@1stfinancialassociates.com</span>.
            </p>
        </div>   
            
          
    </div>
</div>
