import { Injectable } from '@angular/core';
import { MARKS } from '@contentful/rich-text-types';
import { ContentfulRichTextApiResponse } from 'src/app/models/contentful-rich-text-api-response';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {

  constructor() { }

  parseContentfulRichTextColors(resp: ContentfulRichTextApiResponse | undefined): string | undefined {

    if(resp == undefined){
      return undefined;
    }

    //const matchString = "\{(?:.*?)\}"
    //Regex: Match any marking tags 
    /* underline [<u>]
     * paragraph [<p>]
     * italics [<i>] )
     *(underline [<u>], italics [<i>], bold [<b>] with words in {} )
     *(underline [<u>], italics [<i>], bold [<b>] with words in {} )
     */
    const matchString = "(?:<u|i|b|>)*.*(\{.*?\}).*(?:<\/u|\/i|\/b>)*"
    let regex = new RegExp(matchString, "g")
    let matchArr: Array<RegExpMatchArray>  = Array.from(resp.html.matchAll(regex))
    let matchSet: Set<RegexMatch> = new Set(matchArr.map(match => {
        return {
          line: match[0],
          text: match[1]
        };
    }))

    let parsedHTML: string = resp.html;
    
    for(let match of matchSet){
      let wordPartStr = match.text;
      let wordParts: string[] = wordPartStr.replaceAll('{', '').replaceAll("}", '').split(":")
      if(wordParts.length == 2){

        let newHTML: string = `${wordParts[0]}`

        let key =  this.lineToTextMapKey(match.line)
        if(key in resp.textMap){
          resp.textMap[key].marks.forEach( mark => {
            switch(mark as MARKS){
              case MARKS.BOLD:
                newHTML = `<b>${newHTML}</b>`
                break;
              case MARKS.ITALIC:
                newHTML = `<i>${newHTML}</i>`
                break;
              case MARKS.UNDERLINE:
                newHTML = `<u>${newHTML}</u>`
                break;
              case MARKS.CODE:
              case MARKS.SUPERSCRIPT:
              case MARKS.SUBSCRIPT:
            }
          })
        }

        parsedHTML = parsedHTML.replaceAll(match.line, `<span style="color:${wordParts[1]}; text-decoration-color:${wordParts[1]}">${newHTML}</span>`)
      }
      else{
        console.error(`Rich Text '${match}' is formated incorrectly. Expected {words:color}`)
        parsedHTML == undefined
        break;
      }
    }

    return parsedHTML

  }

  private lineToTextMapKey(line: string) : string {
    //Trim white space
    line = line.trim()

    //remove Tags if any
    line = line.replaceAll(/<.*?>/g, "")

    return line
  }
}

export interface RegexMatch {
  line: string
  text: string
}
