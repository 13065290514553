import { Component, OnInit } from '@angular/core';
import { GlobalEntry, GlobalResponse } from 'src/app/models/global-response';
import { CmsService } from 'src/app/services/cms/cms.service';
import { EnvService } from 'src/app/services/env/env.service';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { forkJoin } from 'rxjs';
import { Client } from 'src/app/models/client';
import { ClientListResponse } from 'src/app/models/client-list-response';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  //Icons
  faCaretUp = faCaretUp
  faCaretDown = faCaretDown


  globalData: GlobalResponse | undefined;
  globalEntry: GlobalEntry | undefined;

  clientListData: ClientListResponse | undefined;
  clients: Client[] = [];

  menuOpen: boolean = false

  constructor(private cms: CmsService, private env: EnvService, private router: Router) { }

  ngOnInit(): void {

    forkJoin([
      this.cms.getClientList(this.env.client),
      this.cms.getGlobal(this.env.client)
    ]).subscribe(([clientListRes, globalRes]: [any, any]) => {
      this.globalData = globalRes as GlobalResponse;
      this.globalEntry = this.globalData.entry;
      this.clientListData = clientListRes as ClientListResponse;
      this.clients = this.clientListData.entry.clientList;
    })

    this.cms.getGlobal(this.env.client).subscribe((res: any) => {
      console.log(res);
      this.globalData = res as GlobalResponse;
      this.globalEntry = this.globalData.entry;
    })
  }

  onMenuOpen(): void {
    this.menuOpen = true
  }
  onMenuClose(): void {
    this.menuOpen = false
  }

  onNavigateHome(){
    this.router.navigate(["/home"]);
  }


}
