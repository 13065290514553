import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Client } from 'src/app/models/client';
import { ClientListResponse } from 'src/app/models/client-list-response';
import { ServicesPageEntry, ServicesPageResponse } from 'src/app/models/services-page-response';
import { CmsService } from 'src/app/services/cms/cms.service';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit{

  servicesPageData: ServicesPageResponse | undefined;
  clientListData: ClientListResponse | undefined
  clients: Client[] = [];
  servicePage: ServicesPageEntry = {
    servicesList: [],
    title: '',
    servicesImage: {
      id: '',
      mimeType: '',
      title: '',
      url: ''
    },
    servicesHeadline: '',
    servicesText: ''
  }

  constructor(private cms: CmsService, private env: EnvService) { }

  ngOnInit(): void {
    forkJoin([
      this.cms.getClientList(this.env.client),
      this.cms.getServicesPage(this.env.client)
    ]).subscribe(([clientListRes, servicesPageRes]: [any, any]) => {
      console.log("Client List Data");
      console.log(clientListRes);
      console.log("Services Page Data");
      console.log(servicesPageRes);
      this.clientListData = clientListRes as ClientListResponse
      this.clients = this.clientListData.entry.clientList;
      this.servicesPageData = servicesPageRes as ServicesPageResponse;
      this.servicePage = this.servicesPageData.entry

    })
  }

  

}
