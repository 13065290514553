import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { GlobalEntry, GlobalResponse } from 'src/app/models/global-response';
import { CmsService } from 'src/app/services/cms/cms.service';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  globalData: GlobalResponse | undefined;

  globalEntry: GlobalEntry = {
    name: '',
    logo: {
      id: '',
      mimeType: '',
      title: '',
      url: ''
    },
    copyrightYear: '',
    officeNumber: '',
    tollFreeNumber: '',
    faxNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    title: '',
    footerLogo: {
      id: '',
      mimeType: '',
      title: '',
      url: ''
    }
  }

  constructor(private cms: CmsService, private env: EnvService) { }

  ngOnInit(): void {

    forkJoin([
      this.cms.getGlobal(this.env.client),
    ]).subscribe(([globalRes]: [any]) => {
      console.log("Global Data");
      console.log(globalRes);
      this.globalData = globalRes as GlobalResponse;
      this.globalEntry = this.globalData.entry

    })
  }

}
