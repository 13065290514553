import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { CdaAsset } from 'src/app/models/cda-asset';
import { Client } from 'src/app/models/client';
import { ClientListResponse } from 'src/app/models/client-list-response';
import { ContentfulRichTextApiResponse } from 'src/app/models/contentful-rich-text-api-response';
import { GlobalEntry, GlobalResponse } from 'src/app/models/global-response';
import { HomePageEntry, HomePageResponse } from 'src/app/models/home-page-response';
import { ServicesPageEntry, ServicesPageResponse } from 'src/app/models/services-page-response';
import { CmsService } from 'src/app/services/cms/cms.service';
import { ContentfulService } from 'src/app/services/contentful/contentful.service';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  homePageData: HomePageResponse | undefined;
  clientListData: ClientListResponse | undefined;
  globalData: GlobalResponse | undefined;
  servicesData: ServicesPageResponse | undefined;

  
  //HomePage
  headlineContentHTML: string | undefined;
  heroImage: CdaAsset | undefined;
  responsiveOptions: any[] = [];
  products: any[] = [];
  clients: Client[] = [];
  homePage: HomePageEntry = {
    title: '',
    headlineTitle: '',
    headlineText: '',
    headlineContent: {
      html: '',
      textMap: {}
    },
    heroImage: {
      id: '',
      mimeType: '',
      title: '',
      url: ''
    },
    clientPageHeader: '',
    clientPageText: '',
    welcomeHeader: '',
    welcomeText: '',
    serviceHeadline: '',
    serviceHeadlineText: '',
    service1Name: '',
    service1Image: {
      id: '',
      mimeType: '',
      title: '',
      url: ''
    },
    service2Name: '',
    service2Image: {
      id: '',
      mimeType: '',
      title: '',
      url: ''
    },
    service3Name: '',
    service3Image: {
      id: '',
      mimeType: '',
      title: '',
      url: ''
    },
    
  };
  globalEntry: GlobalEntry = {
    name: '',
    logo: {
      id: '',
      mimeType: '',
      title: '',
      url: ''
    },
    copyrightYear: '',
    officeNumber: '',
    tollFreeNumber: '',
    faxNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    title: '',
    footerLogo: {
      id: '',
      mimeType: '',
      title: '',
      url: ''
    }
  }
  servicesEntry: ServicesPageEntry = {
    title: '',
    servicesImage: {
      id: '',
      mimeType: '',
      title: '',
      url: ''
    },
    servicesHeadline: '',
    servicesText: '',
    servicesList: []
  }


  constructor(private cms: CmsService, private env: EnvService, private contentful:  ContentfulService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    // this.responsiveOptions = [
    //   {
    //       breakpoint: '1199px',
    //       numVisible: 1,
    //       numScroll: 1
    //   },
    //   {
    //       breakpoint: '991px',
    //       numVisible: 2,
    //       numScroll: 1
    //   },
    //   {
    //       breakpoint: '767px',
    //       numVisible: 1,
    //       numScroll: 1
    //   }
    // ];

    forkJoin([
      this.cms.getHomePage(this.env.client),
      this.cms.getClientList(this.env.client),
      this.cms.getGlobal(this.env.client),
      this.cms.getServicesPage(this.env.client)
    ]).subscribe(([homePageRes, clientListRes, globalRes, servicesRes]: [any, any, any, any]) => {
      console.log("Home Page Data");
      console.log(homePageRes);
      console.log("Client List Data");
      console.log(clientListRes);
      console.log("Global Data");
      console.log(globalRes);
      this.homePageData = homePageRes as HomePageResponse;
      this.clientListData = clientListRes as ClientListResponse;
      this.globalData = globalRes as GlobalResponse;
      this.servicesData = servicesRes as ServicesPageResponse;
      this.clients = this.clientListData.entry.clientList;
      this.homePage = this.homePageData.entry
      this.globalEntry = this.globalData.entry
      this.servicesEntry = this.servicesData.entry


      // if(headlineContent){
      //   let parsedHTML = this.contentful.parseContentfulRichTextColors(headlineContent)
      //   if(parsedHTML){
      //     this.headlineContentHTML = this.sanitizer.bypassSecurityTrustHtml(parsedHTML) as string
      //   }
        
      // }

    })
  }


}
