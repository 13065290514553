<div class="container">
    <div class="hero-container">
        <img class="hero-img" [src]="image">
    </div>

    <span class="content-header">Fequently Asked Questions (FAQs)</span>

    
    <mat-tab-group class="tab-group" animationDuration="0ms" [disableRipple]="true" (selectedTabChange)="onTabChanged($event)">
        <mat-tab label="employees">
            <ng-template mat-tab-label >
            <span class="tab-link">FOR {{clientShortName}} EMPLOYEES</span>
            </ng-template>
            <div class=faq-container>
                <span class="faq-intro">{{ffaLongName}} ({{ffaShortName}}) manages and administers the {{clientLongName}} 
                    ({{clientShortName}}) Child Care Subsidy Program (CCSP) on behalf of the {{clientShortName}}, and its employees nationwide. {{ffaShortName}} has put together a list 
                    of Frequently Asked Questions (FAQs) to help answer questions that {{clientShortName}} employees may have about the {{clientShortName}} CCSP.
                </span>
                <span class="faq-question">Q1: What is the {{clientShortName}} CCSP?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer1Paragraph1"
                    ></markdown>
                    <br><br>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer1Paragraph2"
                    ></markdown>
                    <br><br> 
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer1Paragraph3"
                    ></markdown>
                </span>
                <span class="faq-question">Q2: When can a new employee apply for the {{clientShortName}} CCSP?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer2NewEmployeeEligibility"
                    ></markdown>
                </span>
                <span class="faq-question">Q3: What are the {{clientShortName}} CCSP eligibility requirements for {{clientShortName}} employees?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer3EmployeeEligibility"
                    ></markdown>
                    <br><br>
                    1. <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer3Item1EmployeeRequirement"
                    ></markdown>
                    <br><br> 
                    2. Have a child enrolled in, or will be enrolled in, a licensed and/or regulated childcare facility, or with a licensed or regulated home-based 
                    childcare provider (licensed and/or regulated by the State and/or local authorities where the childcare service is provided). 
                    <br><br>
                    3. Your child must have the following relationship to you: a) a biological child who lives with you; b) an adopted child; c) a stepchild; d) a foster child; 
                    e) a child for whom a judicial determination of support has been obtained; or f) a child who you, your spouse or domestic partner, are the parent or legal 
                    guardian of, and to whose support you make regular and substantial contributions. 
                    <br><br>
                    4. Your child must be age 13 or under, or under age 18 if your child has a disability or has disabilities. A child with a disability child is defined as one 
                    who is unable to care for himself or herself based on a physical or mental incapacity as determined by a physician or licensed or certified psychologist or 
                    psychiatrist.
                    <br><br> 
                    5. Have a total family income (TFI) that does not exceed {{tfi}} per year. TFI is determined by looking at the adjusted gross income (AGI), for yourself 
                    and your spouse or domestic partner, if applicable, as reported on your most recently filed federal income tax return (IRS Form1040, IRS Form 1040A, or 
                    IRS 1040EZ).
                </span>
                <span class="faq-question">Q4: How can I determine what the amount of my {{clientShortName}} CCSP benefit will be?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer4AwardAmount"
                    ></markdown>
                    <br><br>
                    <span class="faq-img"><img src="/assets/images/{{clientShortName}} CCSP Benefit Table.png"></span>
                    <br><br> 
                    We have provided a few examples of how the monthly {{clientShortName}} CCSP childcare subsidy benefit amount is calculated below: 
                    <br><br>
                    <span class="faq-answer-label">Example 1: </span>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer4Example1"
                    ></markdown>
                    <br><br>
                    <span class="faq-answer-label">Example 2: </span>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer4Example2"
                    ></markdown>
                    <br><br> 
                    <span class="faq-answer-label">Example 3: </span>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer4Example3"
                    ></markdown>
                </span>
                <span class="faq-question">Q5: How can I apply for an {{clientShortName}} CCSP childcare subsidy?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span> Complete an <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(opmForm1643)">OPM Form 1643</a>, Child Care Subsidy Program Application, for the {{clientShortName}} CCSP. 
                    {{clientShortName}} employees may download an application from this website by following this link, <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(opmForm1643)">OPM Form 1643</a>. This is a fillable PDF form. 
                    <br><br>
                    In addition to submitting a completed OPM 1643 Form, you must also submit: 
                    <br><br> 
                    1. A completed, signed and dated <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(recertificationPackageChecklist)">{{clientShortName}} CCSP Application Checklist</a>  
                    <br><br>
                    2. A signed and dated copy of your most recently filed federal income tax return (IRS Form 1040 must be signed by both you and your spouse if you filed 
                    Married filing Jointly). If you filed Married filing Separately, you have to send a signed and dated copy of your federal tax return and your spouse’s 
                    return.  
                    <br><br>
                    3. A copy of your (and your spouse or partner, if applicable) 2 most recent Leave and Earnings Statement (LES) (paycheck stubs for your spouse or partner, 
                    if applicable). 
                    <br><br> 
                    4. A copy of the your most recent SF-50, Notification of Personnel Action.
                    <br><br>
                    5. Have your childcare provider(s) complete an <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(opmForm1644)">OPM Form 1644</a>. 
                    <strong>Please note: Your provider does not need to fill out Section IV, Provider’s Financial Institution Information.</strong>
                    <br><br>
                    6. A copy of your childcare provider’s current license or proof of compliance with State and/or local childcare regulations.
                    <br><br>
                    7. A copy of your childcare provider’s rate sheet or fee schedule, that your childcare provider gives to parents to show how much they charge weekly 
                    for their childcare services.
                    <br><br>
                    8. A completed and signed IRS Form W-9 from each of your childcare providers.
                    <br><br>
                    9. A copy of the birth certificate for each child who will be participating in the {{clientShortName}} CCSP.
                    <br><br>
                    Please note that your childcare provider can submit all their required forms and supporting documents (OPM 1644 Form, IRS Form W-9, Child Care Provider License, 
                    etc.) directly to {{ffaShortName}}. Your childcare provider does not have to give their required forms and other documents to the parent or {{clientShortName}} employee.
                    <br><br>
                    The {{clientShortName}} CCSP application and all the required supporting documents can be mailed, emailed or faxed to:
                    <br><br>
                    <span class="faq-address">
                        <span>{{ffaLongName}}</span>
                        <span>{{clientShortName}} CCSP</span>
                        <span>7079 Hayden Quarry RD</span>
                        <span>Lithonia, GA 30038</span>
                        <span>Ph: (800) 453-8151</span>
                        <span>Fax: (770) 484-9313</span>
                        <span>Email: <a class="faq-link" href="mailto:{{newAppEmail}}">{{newAppEmail}}</a></span>
                    </span> 
                </span>
                <span class="faq-question">Q6: What are my options if my {{clientShortName}} CCSP application is denied?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer6ApplicationDenial"
                    ></markdown>
                </span>
                <span class="faq-question">Q7: Will I be notified that I am eligible to receive an award?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>Yes, {{ffaShortName}} will email an {{clientShortName}} CCSP Award Letter and an {{clientShortName}} CCSP Award Agreement to all 
                    {{clientShortName}} employees determined to be eligible to receive an award. In addition, {{ffaShortName}} will notify all employees who were determined not to be eligible 
                    to receive an award and provide the reason(s) for such determination.
                </span>
                <span class="faq-question">Q8: How does the {{clientShortName}} CCSP interact with other childcare subsidies that I receive?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>Your subsidy from the Child Care Subsidy Program will be reduced if you receive a state, county, 
                    local, or any other childcare subsidy from any other source(s). Also, you are responsible for determining whether acceptance of {{clientShortName}} subsidy will affect your 
                    eligibility for a childcare subsidy from any other source(s).
                </span>
                <span class="faq-question">Q9: What childcare providers are eligible?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>The childcare provider can be a Center-Based or Family Home-Based childcare provider. The childcare 
                    provider must be licensed and/or regulated in the state or location in where the childcare services are provided. The childcare provider is required to submit 
                    proof of being licensed and/or regulated. If you don’t currently have licensed childcare provider, information about identifying childcare services is 
                    included on the U.S. Department of Health and Human Services’ Administration for Children and Families website at: <a class="faq-link" href="http://www.childcare.gov">http://www.childcare.gov</a>
                </span>
                <span class="faq-question">Q10: Once I receive an award letter, what should I do next?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>You must fully complete, sign, and date the {{clientShortName}} CCSP Award Agreement included with your {{clientShortName}} Award 
                    Letter. You may then fax or email a copy of the signed {{clientShortName}} CCSP Award Agreement to FFA. The fax number for FFA is: <span class="faq-link">(770) 484-9313</span>, and the email address is: <a class="faq-link" href="mailto:{{newAppEmail}}">{{newAppEmail}}</a>
                </span>
                <span class="faq-question">Q11: How do I notify FFA of the amount incurred for childcare charges for each month?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>After the last Friday of each month, you must send {{ffaShortName}} a 
                    <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(monthlyInvoice)">{{clientShortName}} CCSP Monthly Invoice Form</a> which will show the total charges incurred for childcare services rendered each month. The childcare charges should be 
                    stated as weekly totals, and the weekly totals should be added to get the total childcare expense for each month. Each week ends on a Friday. Most months 
                    will have 4 weeks. You can determine how many weeks are in a month by counting the number of Fridays in the month. If the Friday of a week has a date in 
                    the next month, then that entire week should not go on the current month’s invoice, it should go on the next month’s invoice. You and your childcare 
                    provider must sign and date the {{clientShortName}} CCSP invoice for each month. If you have more than one (1) childcare provider, you must submit a separate 
                    {{clientShortName}} CCSP Monthly Invoice form for each childcare provider.
                </span>
                <span class="faq-question">Q12: How can I get a copy of the {{clientShortName}} CCSP Monthly Invoice and who will send it to my provider?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>You may obtain it on our website by following this link: <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(monthlyInvoice)">{{clientShortName}} CCSP Monthly Invoice Form</a> 
                    The <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(monthlyInvoice)">{{clientShortName}} CCSP Monthly Invoice Form</a> is a PDF fillable form. You may complete it online using your keyboard. When you input the weekly childcare charges for 
                    each week, the form will automatically calculate the monthly invoice total. Once you have completed the monthly invoice form, you and your childcare 
                    provider may sign it with a digital signature if you have a digital signature. If you do not have a digital signature, then you can print the completed 
                    invoice, sign it and have your childcare provider sign it.
                </span>
                <span class="faq-question">Q13: How are childcare subsidy benefits paid?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>Each month, either you or your childcare provider will forward the {{clientShortName}} CCSP Monthly Invoice Form 
                    to {{ffaShortName}}. Both you and your licensed childcare provider must sign the invoice form to certify on the form that the childcare services were provided 
                    for your child and you must certify that you continue to be a permanent full-time employee with {{clientShortName}}. Once {{ffaShortName}} receives and processes your monthly 
                    invoice, payments will be made directly to your childcare provider via a paper check or an electronic ACH direct deposit. Payments are not made to the {{clientShortName}} 
                    employee. You are responsible for paying your childcare provider the difference between your total monthly childcare charges and your monthly childcare subsidy 
                    benefit each month.
                </span>
                <span class="faq-question">Q14: If I change childcare providers when do I notify FFA, and what documents should be submitted?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>You must notify {{ffaShortName}} immediately. We must have a completed <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(opmForm1644)">OPM Form 1644</a> for your new 
                    childcare provider, and a copy of the new provider’s current license or proof of compliance with state and/or local childcare regulations. In addition, the new 
                    child care provider must submit the following other documents to {{ffaShortName}} before any {{clientShortName}} CCSP payments can be made to the new child care provider: 
                    IRS Form W-9, and a copy of the new child care provider’s rate sheet that shows how much they charge for child care services. {{ffaShortName}} will not be able 
                    to process your {{clientShortName}} CCSP monthly invoice without these items.
                </span>
                <span class="faq-question">Q15: Are there any federal income tax implications or consequences for receiving a {{clientShortName}} CCSP benefit award?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>The tax laws regarding childcare credits and subsidies are complicated. You should be aware that 
                    there could be tax consequences to receiving childcare subsidy assistance greater than $2,500 per year (if married, filing separately) or $5,000 per year 
                    (if filing as a married couple, single, or head of household). Also, the assistance that you receive for a child who does not meet the 
                    Internal Revenue Service’s definition of a dependent could be treated as taxable income. We encourage each {{clientShortName}} employee to consult with your own personal 
                    tax advisor if you have questions, concerns, or if you need help or advice with your own personal tax situation.
                </span>
                <span class="faq-question">Q16: Once I have been determined eligible to receive an award, do I have to submit a new application each year?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>No. However, you will need to re-certify your continued eligibility and we will need to determine 
                    your current subsidy percent annually no later than April 30th of each year. In order for us to determine your continued eligibility to receive an {{clientShortName}} CCSP 
                    award, and the correct subsidy percent, each year you must complete the recertification process and submit copies of the following documents to FFA: 
                    <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(opmForm1643)">OPM Form 1643</a>, 
                    <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(opmForm1644)">OPM Form 1644</a>, 
                    <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(recertificationPackageChecklist)">{{clientShortName}} CCSP Recertification Package Checklist Form</a>, your most recent signed and dated federal income tax return, two most recent pay 
                    statements (for you and your spouse, if applicable), your most recent SF-50, and a current copy of your child care provider’s license (if the previous license 
                    has expired), a current copy of your child care provider’s current rate sheet, and if you are adding a new child to the {{clientShortName}} CCSP, we will need a copy of the 
                    birth certificate for each new child added.
                </span>
                <span class="faq-question">Q17: What if I have additional questions?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer17AdditionalQuestions"
                    ></markdown>
                    <br><br>
                    <span class="faq-address">
                        <span>{{ffaLongName}}</span>
                        <span>{{clientShortName}} CCSP</span>
                        <span>7079 Hayden Quarry RD</span>
                        <span>Lithonia, GA 30038</span>
                        <span>Ph: (800) 453-8151</span>
                        <span>Fax: (770) 484-9313</span>
                        <span>Email: <a class="faq-link" href="mailto:{{newAppEmail}}">{{newAppEmail}}</a></span>
                    </span> 
                </span>
            </div>
        </mat-tab>
        <mat-tab label="providers">
            <ng-template mat-tab-label>
                <span class="tab-link">For Childcare Providers</span>
            </ng-template>
            <div class=faq-container>
                <span class="faq-intro">{{ffaLongName}} ({{ffaShortName}}) manages and administers the {{clientLongName}} ({{clientShortName}}) 
                    Child Care Subsidy Program (CCSP) on behalf of the {{clientShortName}}, and its employees nationwide. {{ffaShortName}} has put together a list of 
                    Frequently Asked Questions (FAQs) to help answer questions that childcare providers of {{clientShortName}} employees may have about the {{clientShortName}} CCSP.
                </span>
                <span class="faq-question">Q1: What is the {{clientShortName}} CCSP?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer1Paragraph1"
                    ></markdown>
                    <br><br>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer1Paragraph2"
                    ></markdown>
                    <br><br> 
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer1Paragraph3"
                    ></markdown>
                </span>
                <span class="faq-question">Q2: When can a new employee apply for the {{clientShortName}} CCSP?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>
                    <markdown class="markdown" ngPreserveWhitespaces
                        [data]="answer2NewEmployeeEligibility"
                    ></markdown>
                </span>
                <span class="faq-question">Q3: What are the {{clientShortName}} CCSP eligibility requirements for childcare providers?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>A: The {{clientShortName}} CCSP is open to all licensed and/or regulated childcare providers, who provide 
                    childcare services to eligible children of parents who work full-time for the {{clientShortName}}, including those {{clientShortName}} employees assigned to U.S. territories, or possessions 
                    domestic who meet the total family income (TFI) requirements. 
                    <br><br>
                    The {{clientShortName}} CCSP is open to all types of licensed and/or regulated childcare providers, including: 
                    <br><br>
                    • Center-Based childcare providers 
                    <br>
                    • Family Home-Based childcare providers
                    <br>
                    • Accredited public and private schools 
                    <br>
                    • Church-based childcare providers 
                    <br>
                    • Other types of childcare facilities (YMCA, YWCA, Boys & Girls Clubs, etc.). 
                    <br><br>
                    The most important factors for determining whether a childcare provider is eligible or not are: (1) the childcare provider’s facility is safe and secure for 
                    the children enrolled in its care, (2) the childcare provider is duly licensed and/or regulated by the state, county or local authorities, (3) the teachers 
                    and staff at the childcare facility undergo pre-employment background checks, and additional periodic background checks during employment to ensure that they 
                    have been properly vetted to provide care for children , and (4) the childcare facility is inspected regularly to ensure that it is a safe environment for the 
                    children enrolled in its care.
                </span>
                <span class="faq-question">Q4: What documents are required to be submitted to {{ffaShortName}}, before a childcare provider can be approved to participate in the {{clientShortName}} CCSP?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>Most of the required forms are fillable PDF forms. Childcare providers may send the required documents 
                    directly to {{ffaShortName}}, or they can give these documents to the {{clientShortName}} parent so that the parent can send the documents to {{ffaShortName}}. If the childcare 
                    provider does send the required documents directly to {{ffaShortName}}, please make sure that they put the name of the {{clientShortName}} parent, and their child(ren) name(s) 
                    somewhere on their submission package to {{ffaShortName}}. 
                    <br><br>
                    We have provided a list below, of the required documents that childcare providers need to submit to {{ffaShortName}} before they can be approved to participate in the 
                    {{clientShortName}} CCSP:
                    <br><br>
                    1. A completed, sign and dated <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(opmForm1644)">OPM Form 1644</a>. 
                    <strong>Please note: Childcare providers <span class="high-priority">should not</span> complete Section IV, Provider’s Financial Institution Information.</strong> 
                    <br><br>
                    2. A copy of your current childcare provider license, or proof of compliance with state and/or local childcare regulations. If a childcare provider is “Exempt” 
                    from licensing requirements, they must provide documented proof that their childcare facility is “Exempt” from licensing requirements, from the appropriate 
                    regulatory authority, such as the: state, county, or local authorities.
                    <br><br>
                    3. A copy of the childcare provider’s rate sheet or fee schedule, that the childcare provider gives to parents to show how much they charge weekly for their 
                    childcare services.
                    <br><br>
                    4. A completed and signed <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(irsFormW9)">IRS Form W-9</a>, Request for Taxpayer Identification Number and Certification. 
                    <br><br>
                    5. If a childcare provider prefers to be paid the monthly childcare subsidy with an electronic ACH payment, directly to their checking account, instead of a 
                    paper check each month, please complete the <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(childCareProviderACHAuthorization)">Childcare Provider ACH Authorization Form</a>. 
                    <br><br>
                    Please note that all the required supporting documents can be mailed, emailed or faxed to:
                    <br><br>
                    <span class="faq-address">
                        <span>{{ffaLongName}}</span>
                        <span>{{clientShortName}} CCSP</span>
                        <span>7079 Hayden Quarry RD</span>
                        <span>Lithonia, GA 30038</span>
                        <span>Ph: (800) 453-8151</span>
                        <span>Fax: (770) 484-9313</span>
                        <span>Email: <a class="faq-link" href="mailto:{{newAppEmail}}">{{newAppEmail}}</a></span>
                    </span> 
                </span>
                <span class="faq-question">Q5: How does a childcare provider notify {{ffaShortName}} what the total costs are for childcare services rendered each month?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>After the last Friday in each month, the childcare provider and/or the {{clientShortName}} parent must complete and 
                    submit to {{ffaShortName}} each month, a <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(monthlyInvoice)">{{clientShortName}} CCSP Monthly Invoice Form</a> 
                    which will show the total charges incurred for childcare services rendered during each 
                    month. The childcare charges should be stated as weekly totals, and the weekly totals should be added to get the total childcare expense for each month. 
                    Each week ends on a Friday. Most months will have 4 weeks. You can determine how many weeks are in a month by counting the number of Fridays in the month. 
                    If the Friday of a week has a date in the next month, then that entire week should not go on the current month’s invoice, it should go on the next month’s 
                    invoice. Both the childcare provider and the {{clientShortName}} parent must sign and date the {{clientShortName}} CCSP invoice for each month. You can put up to 4 children from the same 
                    family on the same invoice each month.
                </span>
                <span class="faq-question">Q6: How are childcare subsidy benefits paid?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>Each month, either the childcare provider or the {{clientShortName}} parent will submit the {{clientShortName}} CCSP Monthly 
                    Invoice Form to {{ffaShortName}}. Both the childcare provider and the {{clientShortName}} parent must sign the invoice form to certify on the form that the childcare services 
                    were provided by the childcare facility. Once {{ffaShortName}} receives and processes the monthly invoice form, subsidy benefit payments will be made directly 
                    to the childcare provider. {{ffaShortName}} will process a paper check each month, unless the childcare provider has opted to receive an electronic ACH payment 
                    each month, by completing and submitting the FFA Childcare Provider ACH Payment Authorization Form to {{ffaShortName}}. Payments are not made to the {{clientShortName}} employee. 
                    <strong>The {{clientShortName}} parent is responsible for paying the childcare provider the difference between the total monthly childcare charges and the monthly childcare 
                    subsidy benefit paid by the {{clientShortName}} CCSP each month.</strong>
                </span>
                <span class="faq-question">Q7: Once an {{clientShortName}} parent has been determined eligible to receive a childcare subsidy benefit award, does the childcare provider have to submit a new application each year?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>No. However, the {{clientShortName}} CCSP subsidy benefit award is only valid for up to 1 year. Every {{clientShortName}} parent 
                    is required to re-certify their continued eligibility annually, and {{ffaShortName}} will need to determine their current subsidy percent annually no later than 
                    April 30th of each year. In order for us to determine the {{clientShortName}} parent’s continued eligibility to receive an {{clientShortName}} CCSP award, and the correct subsidy percent, each 
                    year the childcare provider must provide updated documents to FFA, including: <a class="faq-link" [routerLink]="[]" (click)="onFormClicked(opmForm1644)">OPM Form 1644</a>, a current copy of the childcare provider’s license (if the previous 
                    license has expired), a current copy of the childcare provider’s current rate sheet (if the current rates on file have increased or decreased).
                </span>
                <span class="faq-question">Q8: What if I have additional questions?</span>
                <span class="faq-answer"><span class="faq-answer-label">A: </span>For any additional questions, please contact {{ffaShortName}} directly at:
                    <br><br> 
                    <span class="faq-address">
                        <span>{{ffaLongName}}</span>
                        <span>{{clientShortName}} CCSP</span>
                        <span>7079 Hayden Quarry RD</span>
                        <span>Lithonia, GA 30038</span>
                        <span>Ph: (800) 453-8151</span>
                        <span>Fax: (770) 484-9313</span>
                        <span>Email: <a class="faq-link" href="mailto:{{newAppEmail}}">{{newAppEmail}}</a></span>
                    </span>
                </span>
            </div>
        </mat-tab>
    </mat-tab-group>

    
</div>
