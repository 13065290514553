import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvService } from './services/env/env.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'faa-website';

  constructor(env: EnvService){
    if (!env.enableDebug) {
      console.log = function () {};
  }
}

}
