<div class="container">
    <div class="hero-container">
        <img class="hero-img" [src]="servicePage.servicesImage.url">
        <div class="hero-title">Services</div>
    </div>

    <div class="primary-services-container">
        <div class="primary-services-items">
            <div class="primary-services-headline">
                {{servicePage.servicesHeadline}}
            </div>
            <div class="primary-services-text">
                {{servicePage.servicesText}}
            </div>
            <p-card >
                <div class="primary-services-card-content">
                    <div class="primary-services-card-logo" style="background-color: #154987;">

                    </div>
                </div>
            </p-card>
        </div>
        
    </div>
</div>
