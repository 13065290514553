<div class="nav-container">
    <div class="title">
        <div class="icon" *ngIf="globalEntry?.logo">
            <img class="icon" [src]="globalEntry?.logo?.url" (click)="onNavigateHome()">
        </div>
        <!-- <div [class]="logoUrl ? 'title-text' : 'title-text-no-logo'" >
            <div>
                {{clientLongName}}
            </div>
            <div>
                Child Care Subsidy Program
            </div>
        </div> -->
    </div>
    <div class="toolbar">
        <mat-toolbar>
            <!-- <span class="title-text">Federal Aviation Administration Childcare Subsidy Program</span> -->
            <span class="toolbar-spacer"></span>
            <a class="nav-link" routerLink="home" routerLinkActive="nav-link-active">Home</a>
            <a class="nav-link" routerLink="about" routerLinkActive="nav-link-active">About</a>
            <a class="nav-link" routerLink="services" routerLinkActive="nav-link-active">Services</a>
            <a class="nav-link" mat-button [matMenuTriggerFor]="clientPages" 
                (menuOpened)="onMenuOpen()"
                (menuClosed)="onMenuClose()"
            >Client Pages</a>
                <mat-menu #clientPages="matMenu" xPosition="before">
                    <button *ngFor="let client of clients" mat-menu-item>{{client.name}}</button>
                </mat-menu>
            <!-- <i [class]="menuOpen ? 'icon pi pi-angle-up' : 'icon pi pi-angle-down'"></i> -->
            <fa-icon class="client-pages-menu-icon" [icon]="menuOpen ? faCaretUp : faCaretDown"></fa-icon>
            <a class="nav-link" routerLink="contact" routerLinkActive="nav-link-active">Contact</a>
        </mat-toolbar>
    </div>
</div>
<i class=""></i>