<div class="container">
    <div class="hero-container">
        <img class="hero-img" [src]="heroImgUrl">
    </div>

    <span class="content-header">Forms & Documents</span>

    <div class="forms-container">
        <a class="forms-link" [routerLink]="[]" (click)="onFormClicked(informationBrochure)">FAA CCSP Informational Brochure</a>
        <a class="forms-link" [routerLink]="[]" (click)="onFormClicked(applicationChecklist)">FAA CCSP Application Checklist</a>
        <a class="forms-link" [routerLink]="[]" (click)="onFormClicked(opmForm1643)">OPM Form 1643</a>
        <a class="forms-link" [routerLink]="[]" (click)="onFormClicked(opmForm1644)">OPM Form 1644</a>
        <a class="forms-link" [routerLink]="[]" (click)="onFormClicked(irsFormW9)">IRS Form W-9</a>
        <a class="forms-link" [routerLink]="[]" (click)="onFormClicked(monthlyInvoice)">FAA CCSP Monthly Invoice Form</a> 
        <span class="forms-helper"><a class="forms-link" [routerLink]="[]" (click)="onFormClicked(childCareProviderACHAuthorization)">Child Care Provider ACH Authorization Form</a> (Optional)</span>
        <span class="forms-helper"><a class="forms-link" [routerLink]="[]" (click)="onFormClicked(recertificationPackageChecklist)">FAA CCSP Recertification Package Checklist Form</a> (Only required when you are recertifying)</span>
    </div>

</div>