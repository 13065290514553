
<div class="container">
    <div class="hero-container">
        <div class="hero-content">
            <div class="hero-items">
                <p class="hero-header">
                    {{homePage.headlineTitle}}
                </p>
                <p class="hero-text">
                    {{homePage.headlineText}}
                </p>
                <button routerLink="/about" pButton type="button" label="Learn More" class=" hero-button p-button-secondary p-button-outlined"></button>
            </div>
            
        </div>
        <div class="hero-img-content">
            <img *ngIf="homePage.heroImage" class="hero-img" [src]="homePage.heroImage.url">
        </div>
    </div>

    <div class="client-pages-container ">
        <div class="client-pages">
            <p class="homepage-header">{{homePage.clientPageHeader}}</p>
            <p class="client-pages-text">
                {{homePage.clientPageText}}
            </p>
            <p-carousel [value]="clients" [numVisible]="6" [numScroll]="1" [circular]="false" [responsiveOptions]="responsiveOptions">
                <ng-template let-client pTemplate="item">
                    <div class="client-pages-carousel-item">
                        <div class="client-pages-image">
                            <img class="client-logo-img" [src]="client.logo.url"/>
                        </div>
                        <div>
                            <p class="client-pages-name">{{ client.name }}</p>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>
        </div>
    </div>

    <div class="welcome-container">
        <div class="welcome-content">
            <p class="homepage-header">{{homePage.welcomeHeader}}</p>
            <p class="welcome-text">
                {{homePage.welcomeText}}
            </p>
            <button routerLink="/contact" pButton type="button" label="Contact Us" class=" welcome-button p-button-secondary p-button-outlined"></button>
        </div>
    </div>

    <div class="services-container">
        <div class="services-content">
            <div class="services-items">
                <p class="services-header">
                    {{homePage.serviceHeadline}}
                </p>
                <p class="services-text">
                    {{homePage.serviceHeadlineText}}
                </p>
                <button routerLink="/services" pButton type="button" label="Learn More" class=" services-button p-button-secondary p-button-outlined"></button>
            </div>
            
        </div>
        <div class="services-cards">
            <p-card *ngFor="let service of servicesEntry.servicesList; index as i" [subheader]="service.name">
                <ng-template pTemplate="header">
                    <div [ngClass]="{
                        'service-background-1':i==0,
                        'service-background-2':i==1,
                        'service-background-3':i==2
                    }">
                        <img [src]="service.logo.url" class="service-img">
                    </div>      
                </ng-template>
                <a class="service-link" routerLink="/services">Learn More ›</a>
            </p-card>
            <!--
            <p-card [subheader]="homePage.service1Name">
                <ng-template pTemplate="header">
                    <img [src]="homePage.service1Image.url" class="service-img">
                </ng-template>
                <a class="service-link" href="">Learn More ›</a>
            </p-card>
            <p-card [subheader]="homePage.service2Name">
                <ng-template pTemplate="header">
                    <img [src]="homePage.service2Image.url" class="service-img">
                </ng-template>
                <a class="service-link" href="">Learn More ›</a>
            </p-card>
            <p-card [subheader]="homePage.service3Name">
                <ng-template pTemplate="header">
                    <img [src]="homePage.service3Image.url" class="service-img">
                </ng-template>
                <a class="service-link" href="">Learn More ›</a>
            </p-card>
            -->
        </div>
    </div>
</div>


