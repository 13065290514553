import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AboutPageEntry, AboutPageResponse } from 'src/app/models/about-page-response';
import { Client } from 'src/app/models/client';
import { ClientListResponse } from 'src/app/models/client-list-response';
import { GlobalResponse } from 'src/app/models/global-response';
import { CmsService } from 'src/app/services/cms/cms.service';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  aboutPageData: AboutPageResponse | undefined;
  clientListData: ClientListResponse | undefined
  clients: Client[] = [];

  aboutPage: AboutPageEntry = {
    title: '',
    aboutUsImage: {
      id: '',
      mimeType: '',
      title: '',
      url: ''
    },
    aboutUsHeadline: '',
    aboutUsText: '',
    aboutUsClientListHeadline: '',
    aboutUsClientListText: '',
    
  }


  constructor(private cms: CmsService, private env: EnvService) { }

  ngOnInit(): void {

    forkJoin([
      this.cms.getClientList(this.env.client),
      this.cms.getAboutPage(this.env.client)
    ]).subscribe(([clientListRes, aboutPageRes]: [any, any]) => {
      console.log("Client List Data");
      console.log(clientListRes);
      console.log("About Page Data");
      console.log(aboutPageRes);
      this.clientListData = clientListRes as ClientListResponse
      this.clients = this.clientListData.entry.clientList;
      this.aboutPageData = aboutPageRes as AboutPageResponse;
      this.aboutPage = this.aboutPageData.entry

    })

  }

}
