import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http'

import { EnvServiceProvider } from './services/env/env.service.provider';

import { HomeComponent } from './components/home/home.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutComponent } from './components/about/about.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { FormsComponent } from './components/forms/forms.component';
import { SecurityComponent } from './components/security/security.component';
import { ContactComponent } from './components/contact/contact.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { MarkdownModule } from 'ngx-markdown';
import { NgxContentfulRichTextModule } from 'ngx-contentful-rich-text';
//Angular Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
//PrimeNG
import { CarouselModule } from 'primeng/carousel';
import { TagModule } from 'primeng/tag';
import { AvatarModule } from 'primeng/avatar';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import { ServicesComponent } from './components/services/services.component';
//Font Awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationComponent,
    FooterComponent,
    AboutComponent,
    FaqsComponent,
    FormsComponent,
    SecurityComponent,
    ContactComponent,
    TermsComponent,
    PrivacyComponent,
    ServicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatTabsModule,
    MatMenuModule,
    HttpClientModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    NgxContentfulRichTextModule,
    CarouselModule,
    TagModule,
    AvatarModule,
    ButtonModule,
    CardModule,
    FontAwesomeModule

    
  ],
  providers: [EnvServiceProvider],
  bootstrap: [AppComponent]
})
export class AppModule { }
