<div class="footer">
    <div class="footer-container">

        <div class="footer-item footer-logo-margin">
            <img [src]="globalEntry.footerLogo.url" class="icon">
        </div>

        <div class="footer-item">
            <div class="footer-item-links">
                <div class="footer-item-link-container footer-item-link-container-margin">
                    <a class="nav-link" routerLink="home" routerLinkActive="nav-link-active">Home</a>
                    <a class="nav-link" routerLink="about" routerLinkActive="nav-link-active">About</a>
                    <a class="nav-link" routerLink="services" routerLinkActive="nav-link-active">Services</a>
                    <a class="nav-link" routerLink="contact" routerLinkActive="nav-link-active">Contact</a>
                </div>
                <div class="footer-item-link-container">
                    <a class="nav-link" routerLink="security" routerLinkActive="nav-link-active">Security & Compliance</a>
                    <a class="nav-link" routerLink="privacy" routerLinkActive="nav-link-active">Privacy</a>
                    <a class="nav-link" routerLink="terms" routerLinkActive="nav-link-active">Terms of Use</a>
                </div>
            </div>
        </div>

        <div class="footer-item">
            <div class="footer-contact-container">
                <p class="footer-header">Contact Us for More Information:</p>
                <div class="footer-contact-info-container">
                    <div class="footer-contact-info footer-contact-info-margin">
                        <span>{{globalEntry.name}}</span>
                        <span>{{globalEntry.addressLine1}}</span>
                        <span>{{globalEntry.addressLine2}}</span>
                        <span>{{globalEntry.city}}, {{globalEntry.state}} {{globalEntry.zipCode}}</span>
                    </div>
                    <div class="footer-contact-info">
                        <span>Office: {{globalEntry.officeNumber}}</span> 
                        <span>Fax: {{globalEntry.faxNumber}}</span>
                        <span>Toll-Free: {{globalEntry.tollFreeNumber}}</span>
                    </div>
                </div>
                <p class="footer-copyright">Copyright © {{globalEntry.copyrightYear}} First Financial Associates, Inc.</p>
            </div>
        </div>

    </div>
</div>