<div class="container">
    <div class="hero-container">
        <hr class="hero-hr">
        <span class="hero-title">First Financial Associates</span>
        <hr class="hero-hr">
    </div>

    <div class="content">
        <div class="content-item">
            <span class="content-header">Terms of Use</span>
            <p>
                First Financial Associates, Inc. (“First Financial”) provides its content on its websites or applications that post a link to 
                this Terms of Use (the “Website”) subject to the following terms and conditions (the “Terms”). We may periodically change the 
                Terms without prior notice, so please check back from time to time. These Terms were last updated on April 27, 2021. 
                By accessing and using this website, you agree to these Terms. For an explanation of First Financial’s practices and policies related 
                to the collection, use, and storage of our users’ information, please read our Privacy Policy.
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">1. Copyrights</span>
            <p>
                All content and functionality on the Website, including text, graphics, logos, icons, images, and videos and the selection and arrangement 
                thereof, in addition to any concepts, know-how, tools, frameworks, software, applications or other technology, algorithms, models, processes, 
                and industry perspectives underlying or embedded in the foregoing, along with any enhancements to or derivative works thereof 
                (the “Website Content”) is the exclusive property of First Financial or its licensors and, to the extent applicable, is protected by U.S. 
                and international copyright laws. Neither the Website Content nor functionality of the Website, may be copied, reproduced, modified, reverse 
                engineered, altered (including the removal or disabling of any security or technological safeguards, disclaimers, or legends) uploaded, published, 
                uploaded, posted, transmitted, or distributed in any way without our written permission, except for those uses specified in Section 3 – 
                Use of Website content. All rights not expressly granted are reserved. 
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">2. Trademarks</span>
            <p>
                The First Financial trademarks, service marks, designs, and logos (collectively, the “Trademarks”) displayed on the Website are the registered 
                and unregistered Trademarks of First Financial and its licensors. You agree that, except as expressly permitted by us (e.g., through social media 
                sharing tools provided on the Website) or by our licensors, where applicable, you will not refer to or attribute any information to First Financial 
                or its licensors in any public medium (e.g., press release, websites, or public social media) for advertising or promotion purposes, or for the 
                purpose of informing or influencing any third party and that you will not use or reproduce any Trademark of, or imply any endorsement by or 
                relationship with, First Financial or its licensors.
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">3. Use of Website content</span>
            <p>
                First Financial hereby grants you a limited, non-exclusive, non-transferable, revocable license for the term hereof to access and download, display, 
                and print one copy of the Website Content on any single computer solely for your internal, business use, provided that you do not modify the 
                Website Content in any way (including creating derivative works thereof), that you retain all copyright and other proprietary notices displayed on 
                the Website Content, and that you otherwise comply with these Terms. You may not otherwise reproduce, modify, reverse engineer, distribute, transmit, 
                post, or disclose the Website Content without First Financial’s prior written consent. In addition, you may not “mirror” the Website Content or any 
                portion thereof without First Financial’s express written consent. In addition, you shall NOT, without the prior written consent of First Financial: 
                <br><br>
                1. Decompile, reverse engineer, disassemble and/or create derivative works from the Website; 
                <br><br>
                2. Remove or obscure any proprietary notices including, but not limited to, any and all copyright, trademark and patent designations contained in the 
                First Financial Website; 
                <br><br>
                3. Upload, post, email, transmit, publish, re-publish, distribute, display, or otherwise make available the First Financial Website to any third parties; 
                <br><br>
                4. Use the First Financial Website for any commercial, financial, or other beneficial purpose, including, but not limited to, advertising, the 
                exploitation, rental, lease, sale, or resale of the First Financial Website; 
                <br><br>
                5. For a period of more than twenty-four (24) hours, cache or otherwise temporarily store the First Financial Website or component thereof, on any server 
                or other device used to communicate with individual personal computers or personal devices. Such limitation does not include cache which is automatically 
                stored by an individual personal browser application;
                <br><br>
                6. Assign, rent, lend, lease, sell, sublicense, transfer, export from the United States, copy, reproduce, modify, adapt, translate, reverse engineer, 
                decompile, disassemble, extract components, or create derivative works of the Website; or 
                <br><br>
                7. Remove, modify, hide, or otherwise make unreadable or non-viewable any notice, legend, advice, watermark, or other designation contained on the Website, 
                component thereof or output therefrom.
                <br><br>
                8. (a) Co-brand the Website, (b) frame the Website, or (c) hyperlink to the Website, or authorize or engage any other party to do any of the foregoing, 
                without the express prior written permission of an authorized representative of First Financial. For purposes of this Agreement, “co-brand” or “co-branding” 
                means to display a name, logo, trademark, or other means of attribution or identification of any party in such a manner as is reasonably likely to give a 
                user the impression that such other party has the right to display, publish, or distribute this website or content accessible within this website. 
                You agree to cooperate with First Financial in causing any unauthorized co-branding, framing or hyper-linking immediately to cease. 
                Nothing on this Website should be construed as granting directly or indirectly, or by implication any license or right to use any 
                First Financial intellectual property other than as expressly set forth herein. The license granted in this section terminates automatically and 
                immediately if you do not comply with these Terms. 
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">4. User postings</span>
            <p>  
               You acknowledge and agree that First Financial shall own and have the unrestricted right to use, publish, and otherwise exploit any and all information 
               that you post or otherwise publish on the Website in postings, forums or message boards, questionnaire, survey responses, and otherwise, and you 
               acknowledge and agree that, by providing us any such submission, you automatically grant, and hereby do grant, to us a worldwide, non-exclusive, 
               transferable, assignable, sublicensable, fully paid-up, royalty-free, perpetual, irrevocable license and right to use, reproduce, publish, distribute, 
               modify and otherwise exploit such submission for any purpose, and in any form or media, not prohibited by applicable law. In addition, you hereby waive 
               any claims against First Financial for any alleged or actual infringements of any rights of privacy or publicity, intellectual property rights, moral 
               rights, or rights of attribution in connection with First Financial’s use and publication of such submissions. 
               <br><br>
               You covenant that you shall not post or otherwise publish on the Website any materials that (a) are threatening, libelous, defamatory, or obscene; (b) 
               would constitute, or that encourage conduct that would constitute, a criminal offense, give rise to civil liability, or otherwise violate law; (c) 
               infringe the intellectual property, privacy, or other rights of any third parties; (d) contain a computer virus or other destructive element; (e) contain 
               advertising; (f) constitute or contain false or misleading statements; or (g) violates these Terms. 
               <br><br>
               First Financial does not represent or endorse the accuracy of reliability of information posted to the Website by users. In addition, First Financial does 
               not and cannot review all information posted to the Website by users and is not responsible for such information. However, First Financial reserves the 
               right to refuse to post and the right to remove any information, in whole or in part, for any reason or for no reason. 
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">5. Notices of infringement and takedown by First Financial</span>
            <p>
                First Financial prohibits the posting of any information that infringes or violates the copyright rights and/or other intellectual property rights 
                (including rights of privacy and publicity) of any person or entity. If you believe that your intellectual property right (or such a right that you 
                are responsible for enforcing) is infringed by any content on the Website, please write to First Financial at the address shown below, giving a written 
                tatement that contains: (a) identification of the copyrighted work and/or intellectual property right claimed to have been infringed; (b) identification 
                of the allegedly infringing material on the Website that is requested to be removed; (c) your name, address, and daytime telephone number, and an e-mail 
                address if available; (d) a statement that you have a good faith belief that the use of the copyrighted work and/or exercise of the intellectual property 
                ght is not authorized by the owner, its agent, or the law; (e) a statement that the information in the notification is accurate, and, under penalty of 
                perjury, that the signatory is authorized to act on behalf of the owner of the right that is allegedly infringed; and (f) the signature of the intellectual 
                property right owner or someone authorized on the owner’s behalf to assert infringement of the right. First Financial will remove any posted submission that 
                infringes the copyright or other intellectual property right of any person under U.S. law upon receipt of such a statement (or any statement in conformance 
                with 17 U.S.C. 512(c)(3)). U.S. law provides significant penalties for submitting such a statement falsely. Under appropriate circumstances, persons who 
                repeatedly submit infringing or unlawful material will be prohibited from posting further submissions. First Financial’s contact for submission of notices 
                under this Section 5 is: Legal Department, First Financial Associates, Inc., 7079 Hayden Quarry Road, Stonecrest, Georgia 30038. 
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">6. Disclaimers</span>
            <p>
                THE CONTENT AND FUNCTIONALITY ON THE WEBSITE IS PROVIDED WITH THE UNDERSTANDING THAT FIRST FINANCIAL IS NOT HEREIN ENGAGED IN RENDERING PROFESSIONAL ADVICE 
                OR SERVICES TO YOU, NO WEBSITE CONTENT IS INTENDED TO SERVE AS OR SHALL BE DEEMED INVESTMENT, LEGAL, TAX, ACCOUNTING OR OTHER REGULATED ADVICE, AND THAT 
                YOU SHALL REMAIN SOLELY RESPONSIBLE FOR YOUR USE OF ALL WEBSITE CONTENT AND ACKNOWLEDGE THAT ANY RELIANCE UPON THE WEBSITE CONTENT SHALL BE ENTIRELY AT YOUR 
                SOLE OPTION AND RISK. ALL CONTENT AND FUNCTIONALITY ON THE WEBSITE IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, 
                WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. FIRST FINANCIAL AND ITS THIRD-PARTY CONTENT PROVIDERS MAKE 
                NO WARRANTIES, EXPRESS OR IMPLIED, AS TO THE OWNERSHIP, ACCURACY, OR ADEQUACY OF THE WEBSITE CONTENT. FIRST FINANCIAL SHALL HAVE NO LIABILITY OR RESPONSIBILITY 
                FOR ANY INFORMATION PUBLISHED ON LINKED WEBSITES, CONTAINED IN ANY USER SUBMISSIONS PUBLISHED ON THE WEBSITE, OR PROVIDED BY THIRD PARTIES. NEITHER FIRST FINANCIAL 
                NOR ITS THIRD-PARTY CONTENT PROVIDERS SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR LOSSES OR FOR LOST REVENUES OR PROFITS, 
                WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND REGARDLESS OF THE THEORY OF LIABILITY. 
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">7. Indemnification</span>
            <p>
                You hereby indemnify, defend, and hold harmless First Financial and all of its predecessors, successors, parents, subsidiaries, affiliates, officers, directors, 
                shareholders, investors, employees, agents, representatives, and attorneys and their respective heirs, successors, and assigns (“First Financial Indemnified 
                Parties”) from and against any and all liability, expenses, costs, or other losses (“Losses”) incurred by First Financial and/or First Financial Indemnified 
                Parties in connection to any claims arising out of your use of the Website and/or any breach by you of these Terms, including the representations, warranties 
                and covenants you made, if any, by agreeing to these Term. First Financial reserves the right to assume, at its own expense, the exclusive defense and control 
                of any matter otherwise subject to indemnification by you. 
            </p>
        </div>
        <div class="content-item">
            <span class="content-subheader">8. Limitation of Liability</span>   
            <p>
                Under no circumstances shall First Financial be liable to you or any other party for any direct, indirect, special, consequential or exemplary damages, 
                including but not limited to, damages for lost wages, income, profits, business interruption, goodwill or other intangible losses of any kind arising 
                from or relating in any way to (i) your use of, or inability to use, the Sites or the information contained in the Sites; (ii) any failure of performance, 
                error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure; and (iii) any other matter relating 
                to the Sites, even if advised of the possibility of such damages.
            </p>
        </div>
        <div class="content-item">   
            <span class="content-subheader">9. Third-party websites & Providers</span>
            <p>
                We may provide links to third-party websites, and some of the content appearing to be on this Website is in fact supplied, supported, or 
                provided directly or indirectly by third parties, for example, in instances of framing of third-party websites or incorporation through 
                framesets of content supplied by third-party servers. First Financial has no responsibility for these third-party websites, which are governed 
                by the terms of use and privacy policies, if any, of the applicable third-party content providers.
            </p>
        </div>
        <div class="content-item"> 
            <span class="content-subheader">10. Governing law; jurisdiction</span>      
            <p>
                These Terms are governed by the laws of the State of Georgia without reference to the principles of conflicts of laws thereof.
            </p>        
        </div>
    </div>
</div>
