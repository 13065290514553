import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

 // API url
 public apiUrl = 'http://localhost:8081';

 // Whether or not to enable debug mode
 public enableDebug = true;

 //Client
 public client = "unknown"

  constructor() { }
}
